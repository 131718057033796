import { names } from "../assets/names.json";

export const colours = [
  { colour: [0.08235, 0.219607, 0.92549, 1.0], name: "blue" },
  { colour: [0.764705, 0.85098, 0.42745, 1.0], name: "green" },
  { colour: [0.49803, 0.85098, 0.94509, 1.0], name: "lightblue" },
  { colour: [0.75686, 0.55294, 0.83529, 1.0], name: "lilac" },
  { colour: [0.08235, 0.94117, 0.53333, 1.0], name: "mint" },
  { colour: [0.95294, 0.55294, 0.63921, 1.0], name: "pink" },
  { colour: [0.49803, 0.160784, 0.83137, 1.0], name: "purple" },
  { colour: [0.92549, 0.18823, 0.28627, 1.0], name: "red" },
  { colour: [1.0, 0.87058, 0.41568, 1.0], name: "yellow" },
  { colour: [1.0, 0.62745, 0.274509, 1.0], name: "orange" },
];

export const normalize = (val, max, min) => {
  return (val - min) / (max - min);
};

export const scale = (val, max, min) => {
  return ((max - min) * (val - 0)) / (1 - 0) + min;
};

export const chooseStatus = (lastTransfer, maxDuration, seed) => {
  const deltatime =
    maxDuration - (Math.floor(Date.now() / 1000) - lastTransfer);
  const max = parseInt("ffffffffff", 16);
  const seedInt = parseInt(seed.slice(6, 16), 16);
  const thisSeed = (seedInt + lastTransfer) % max;
  if (deltatime <= 604800) {
    return names.end[
      Math.round(scale(normalize(thisSeed, max, 0), colours.length - 1, 0))
    ];
  } else if (deltatime >= 2592000) {
    return names.beginning[
      Math.round(scale(normalize(thisSeed, max, 0), colours.length - 1, 0))
    ];
  } else {
    return names.medium[
      Math.round(scale(normalize(thisSeed, max, 0), colours.length - 1, 0))
    ];
  }
};

export const chooseColour = (seed) => {
  const max = parseInt("ffffffffff", 16);
  return colours[
    Math.round(
      scale(
        normalize(parseInt(seed.slice(16, 26), 16), max, 0),
        colours.length - 1,
        0
      )
    )
  ].colour;
};

export const chooseSecondColour = (seed) => {
  const max = parseInt("ffffffffff", 16);
  return colours[
    Math.round(
      scale(
        normalize(parseInt(seed.slice(18, 28), 16), max, 0),
        colours.length - 1,
        0
      )
    )
  ].colour;
};
