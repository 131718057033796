const components = {
  InfoPanel: {
    heading: "Lifeform #{tokenId}",
    age: "Age: {days} days {hours} hours {seconds} seconds",
    remaining: "Remaining care period:",
    remainingDate:
      "Remaining care period: {days} days {hours} hours {seconds} seconds",
    owner: "Currently in the care of: {owner}",
    give: "GIVE",
    status: "Status: Lifeform #{tokenId} {status}",
  },
};

const views = {
  Home: {
    noLifeforms: "You're not currently caring for any lifeforms",
  },
  Lifeform: {
    noLifeforms: "There is no lifeform here",
  },
  Mint: {
    error: "Something went wrong",
  },
  Header: {
    wrongNetwork: "Please switch to Polygon network",
    connected: "Connected as: ",
    mint: "Create",
  },
  About: {
    para1: `Lifeforms are NFT-based entities.
      Like any living thing, lifeforms need regular care in order to thrive. 
      If not properly looked after, lifeforms die.`,
    para1point5: ` A lifeform that has died will 
      no longer appear in wallets, is not transferable, and cannot be brought 
      back to life in any way. How do you care for a lifeform?`,
    para1point6: `Within 90 days 
      of receiving it, you must give it away.`,
    para2: `Lifeforms are open for public creation. The amount of lifeforms that can be created is uncapped. After lifeforms has been 
      open for some time, this page will include population and life expectancy data. For the moment, this information is unknown.`,
    para2point5: `Lifeforms run on polygon, a proof of stake network with a low 
      ecological footprint. Currently, lifeform creation costs ~10 MATIC.`,
    para3: `The lifeforms on foster at the Kunstverein Hamburg are also physically instatiated. 
      When physically instantiated, lifeforms live on cell phones and grow squishy silicone bodies: small 
      creatures that inhabit our companion devices. Most lifeforms however, will only take digital form`,
    para4: `Lifeforms was created by Sarah Friend, and you can direct questions, comments, and bug reports to her.`,
    para4point5: `The project launches also with many thanks to Simon Denny and Bettina Steinbrügge for providing a 
      physical installation context for the first lifeforms at the Kunstverein in Hamburg, and for undertaking their 
      foster care. To Dre Roelandt, for their patient help fabricating the silicone bodies for the first three lifeforms. And 
      to Marie Claire LeBlanc Flanagan: the seed of this project 
      was planted during conversations with them while they were working on their 
      project Common, an experimental video game about decentralized networks of care and 
      community trust`,
  },
};

export default {
  title: "Lifeforms",
  default: {
    buttonSubmit: "Submit",
    name: "A {noun} requires {adjective} {noun2} and {noun3}",
  },
  ...components,
  ...views,
};
