import { lifeformsContract, metamaskLifeformsContract } from "./";
import web3 from "../web3";

export const TX_BIRTH = Symbol("TX_BIRTH");
export const TX_GIVE = Symbol("TX_GIVE");

export async function getTokenUri(tokenId) {
  return await lifeformsContract.methods.tokenURI(tokenId).call();
}

export async function getSeed(tokenId) {
  return await lifeformsContract.methods.getSeed(tokenId).call();
}

export async function getLifeform(tokenId) {
  const lifeform = await lifeformsContract.methods.getLifeform(tokenId).call();
  return {
    tokenURI: lifeform[0],
    owner: lifeform[1],
    birthtime: lifeform[2],
    ownerBeginning: lifeform[3],
  };
}

export async function birth(user, tokenId) {
  const { transactionHash: txHash } = await metamaskLifeformsContract.methods
    .birth(user, tokenId)
    .send({
      from: user,
      value: web3.utils.toWei("10"),
    });
  return { txHash, txMethod: TX_BIRTH };
}

export async function give(from, to, tokenId) {
  const { transactionHash: txHash } = await metamaskLifeformsContract.methods
    .transferFrom(from, to, tokenId)
    .send({
      from,
    });
  return { txHash, txMethod: TX_GIVE };
}
