const frag = `#ifdef GL_ES
precision highp float;
#endif

uniform vec2      u_resolution;           // viewport resolution (in pixels)
uniform float     u_time;                 // shader playback time (in seconds)
uniform vec4      u_base_color;

float dist(vec2 p0, vec2 pf){return sqrt((pf.x-p0.x)*(pf.x-p0.x)+(pf.y-p0.y)*(pf.y-p0.y));}

void main()
{
    float d = dist(vec2(u_resolution.x*0.4, u_resolution.y*0.5),gl_FragCoord.xy)*(sin(u_time)+3.5)*0.0009;

    float resX = u_resolution.x*0.4*(sin(u_time/1.)*0.5+1.);
    float resY = u_resolution.y*0.4*(sin(u_time/0.5)*0.5+1.);
    float d2 = dist(vec2(resX, resY),gl_FragCoord.xy)*(sin(u_time)+3.5)*0.0009;

    gl_FragColor = mix(u_base_color, vec4(1.0, 1.0, 1.0, 1.0), d);
}`;

export default frag;
