import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import translate from "../services/translate";
import { chooseStatus } from "../services/procGen";

import "../styles/InfoPanel.css";

const InfoPanel = ({
  birthtime,
  currentOwner,
  description,
  lastTransfer,
  tokenId,
  seed,
  setGiving,
}) => {
  const [ageDays, setAgeDays] = useState(0);
  const [ageHours, setAgeHours] = useState(0);
  const [ageSeconds, setAgeSeconds] = useState(0);
  const [fosterDays, setFosterDays] = useState(0);
  const [fosterHours, setFosterHours] = useState(0);
  const [fosterSeconds, setFosterSeconds] = useState(0);
  const [status, setStatus] = useState("");
  const history = useHistory();

  const maxDuration = 7776000;

  const startGiving = (giving) => {
    setGiving(giving);
  };

  useEffect(() => {
    if (seed !== "") {
      const st = chooseStatus(lastTransfer, maxDuration, seed);
      setStatus(st);
    }
  }, [lastTransfer, maxDuration, seed]);

  useEffect(() => {
    const interval = setInterval(() => {
      const deltatime = Math.floor(Date.now() / 1000) - birthtime;
      const deltaFostertime =
        maxDuration - (Math.floor(Date.now() / 1000) - lastTransfer);
      setAgeDays(Math.floor(deltatime / 86400));
      setAgeHours(Math.floor((deltatime - ageDays * 86400) / 3600));
      setAgeSeconds(Math.floor(deltatime - ageDays * 86400 - ageHours * 3600));
      setFosterDays(Math.floor(deltaFostertime / 86400));
      setFosterHours(Math.floor((deltaFostertime - fosterDays * 86400) / 3600));
      setFosterSeconds(
        Math.floor(deltaFostertime - fosterDays * 86400 - fosterHours * 3600)
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [
    birthtime,
    ageDays,
    ageHours,
    setAgeDays,
    setAgeHours,
    setAgeSeconds,
    fosterDays,
    fosterHours,
    setFosterDays,
    setFosterHours,
    setFosterSeconds,
    lastTransfer,
  ]);

  const lifeformView = () => {
    history.push(`/${tokenId}`);
  };

  return (
    <div className="infoPanel">
      <div className="blackContainer">
        <h2 className="title" onClick={lifeformView}>
          {translate("InfoPanel.heading", { tokenId })}
        </h2>
        <p>{description}</p>
        <p>
          {translate("InfoPanel.status", {
            tokenId,
            status,
          })}
        </p>
        <p>
          {translate("InfoPanel.age", {
            days: ageDays,
            hours: ageHours,
            seconds: ageSeconds,
          })}
        </p>
        <p className="overflowPossible">
          {translate("InfoPanel.owner", {
            owner: currentOwner,
          })}
        </p>
        <p>
          {translate("InfoPanel.remainingDate", {
            days: fosterDays,
            hours: fosterHours,
            seconds: fosterSeconds,
          })}
        </p>
        {setGiving && (
          <button className="give" onClick={() => startGiving(tokenId)}>
            {translate("InfoPanel.give")}
          </button>
        )}
      </div>
    </div>
  );
};

InfoPanel.propTypes = {
  birthtime: PropTypes.number.isRequired,
  currentOwner: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  lastTransfer: PropTypes.number.isRequired,
  seed: PropTypes.string.isRequired,
  setGiving: PropTypes.func,
  tokenId: PropTypes.string.isRequired,
};

export default InfoPanel;
