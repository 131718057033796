import LifeformsContract from "lifeforms-contracts/build/contracts/Lifeforms2.json";

import web3, { metamaskWeb3 } from "../web3";

// Utility methods to get contracts

function getContract(abi, address, web3) {
  return new web3.eth.Contract(abi, address);
}

export function getLifeformsContract(address, web3) {
  return getContract(LifeformsContract.abi, address, web3);
}

export async function isContract(address) {
  const code = await web3.eth.getCode(address);
  // A valid contract is a string with `0x` as a prefix. If no other characters
  // follow the prefix it is an invalid contract.
  if (/^0x.+/.test(code)) return true;
  return false;
}

export async function isLifeformsContract() {
  return isContract(process.env.REACT_APP_LIFEFORMS_CONTRACT);
}

export const lifeformsContract = getLifeformsContract(
  process.env.REACT_APP_LIFEFORMS_CONTRACT,
  web3
);

export const metamaskLifeformsContract = getLifeformsContract(
  process.env.REACT_APP_LIFEFORMS_CONTRACT,
  metamaskWeb3
);

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
