import React, { Fragment } from "react";

import View from "../components/View";
import translate from "../services/translate";

import "../styles/About.css";

const About = () => {
  return (
    <Fragment>
      <View>
        <p className="abouttext">{translate("About.para1")}</p>
        <p className="abouttext">{translate("About.para2")}</p>
        <p className="abouttext">{translate("About.para4")}</p>
      </View>
    </Fragment>
  );
};

export default About;
