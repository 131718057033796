import PropTypes from "prop-types";
import React from "react";

const InputFieldset = ({ children, name, label, meta }) => {
  return (
    <p>
      <span htmlFor={name}>{label}</span>

      {meta.isTouched && meta.error ? <p>{meta.error}</p> : null}

      {children}
    </p>
  );
};

InputFieldset.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default InputFieldset;
