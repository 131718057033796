import React from "react";
import PropTypes from "prop-types";

import "../styles/Whiteout.css";

const Whiteout = ({ onClick }) => {
  return <div className="whiteout" onClick={onClick}></div>;
};

Whiteout.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Whiteout;
