import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { enableAccount } from "../store/ethereum/actions";
import Mint from "./Mint";

import "../styles/Header.css";
import translate from "../services/translate";

const Header = () => {
  const dispatch = useDispatch();

  const { account, chainId, hasProvider } = useSelector(
    (state) => state.ethereum
  );

  const onEnable = (event) => {
    event.preventDefault();
    dispatch(enableAccount());
  };

  return (
    <div className="header">
      {hasProvider ? (
        !account ? (
          <button onClick={onEnable}>
            <h2>Connect</h2>
          </button>
        ) : chainId !== process.env.REACT_APP_CHAINID ? (
          <h2 className="headerMsg">{translate("Header.wrongNetwork")}</h2>
        ) : (
          <Fragment>
            <h2 className="headerMsg">
              {translate("Header.connected")}
              <span className="available">{account}</span>
            </h2>
            <Mint />
          </Fragment>
        )
      ) : null}
    </div>
  );
};

export default Header;
