import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import View from "../components/View";
import { getLifeform } from "../services/contracts/lifeforms";
import FoundGLSLWrapper from "../components/FoundGLSLWrapper";
import { useRequestId, useRequest } from "../hooks/request";
import { getRequest } from "../store/api/actions";
import GalleryInfoPanel from "../components/GalleryInfoPanel";
import frag1 from "../fragments/gallery-1";
import frag2 from "../fragments/gallery-2";
import frag3 from "../fragments/gallery-3";

const uniforms = {};

const Gallery = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [lifeformData, setLifeformData] = useState({});
  const [frag, setFrag] = useState("");
  const requestId = useRequestId();
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (id === "1") {
      setFrag(frag1);
      setIsLoading(false);
    } else if (id === "2") {
      setFrag(frag2);
      setIsLoading(false);
    } else {
      setFrag(frag3);
      setIsLoading(false);
    }
  }, [setFrag, id]);

  useRequest(requestId, {
    onError: () => {
      setDescription("");
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        setDescription("");
      } else {
        setDescription(response.description);
      }
    },
  });

  useEffect(() => {
    dispatch(
      getRequest({
        id: requestId,
        endpoint: process.env.REACT_APP_API_URL,
        path: [id],
      })
    );
    const interval = setInterval(() => {
      dispatch(
        getRequest({
          id: requestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: [id],
        })
      );
      window.location.reload();
    }, 3600000);
    return () => clearInterval(interval);
  }, [dispatch, id, requestId]);

  useEffect(() => {
    const getLifeformData = async () => {
      const lifeform = await getLifeform(id);
      setLifeformData(lifeform);
    };

    getLifeformData();
  }, [setLifeformData, id]);

  return (
    <Fragment>
      <View>
        {!isLoading && (
          <FoundGLSLWrapper
            class="canvasContainer"
            frag={frag}
            setUniforms={uniforms}
          />
        )}
        <GalleryInfoPanel
          birthtime={parseInt(lifeformData.birthtime)}
          description={description}
          tokenId={id}
        />
      </View>
    </Fragment>
  );
};

export default Gallery;
