import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import translate from "../services/translate";
//import names from "../assets/names.json";
import "../styles/InfoPanel.css";

const GalleryInfoPanel = ({ birthtime, description, tokenId }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const deltatime = Math.floor(Date.now() / 1000) - birthtime;
      setDays(Math.floor(deltatime / 86400));
      setHours(Math.floor((deltatime - days * 86400) / 3600));
      setSeconds(Math.floor(deltatime - days * 86400 - hours * 3600));
    }, 1000);
    return () => clearInterval(interval);
  }, [birthtime, days, hours, setDays, setHours, setSeconds]);

  return (
    <div className="infoPanelGallery">
      <div className="blackContainer containerGallery">
        <h2 className="title">{translate("InfoPanel.heading", { tokenId })}</h2>
        <p>{description}</p>
        <p>
          {translate("InfoPanel.age", {
            days,
            hours,
            seconds,
          })}
        </p>
        <button className="give">{translate("InfoPanel.give")}</button>
      </div>
    </div>
  );
};

GalleryInfoPanel.propTypes = {
  birthtime: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
};

export default GalleryInfoPanel;
