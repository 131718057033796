const frag = `#ifdef GL_ES
precision highp float;
#endif

uniform vec2      u_resolution;           // viewport resolution (in pixels)
uniform float     u_time;                 // shader playback time (in seconds)
uniform vec4      u_base_color;
uniform vec4      u_first_color;
uniform vec4      u_accent_color;
uniform vec3      u_ball_color;

float dist(vec2 p0, vec2 pf){return sqrt((pf.x-p0.x)*(pf.x-p0.x)+(pf.y-p0.y)*(pf.y-p0.y));}

float sphIntersect( vec3 ro, vec3 rd, vec4 sph )
{
    vec3 oc = ro - sph.xyz;
    float b = dot( oc, rd );
    float c = dot( oc, oc ) - sph.w*sph.w;
    float h = b*b - c;
    if( h<0.0 ) return -1.0;
    h = sqrt( h );
    return -b - h;
}

void main()
{
    vec2 p = (2.0*vec2(gl_FragCoord)-u_resolution.xy) / u_resolution.y;
    float d = dist(vec2(u_resolution.x*0.5, u_resolution.y*0.7),gl_FragCoord.xy)*(sin(u_time)+3.5)*0.0009;

    float resX = u_resolution.x*0.4*(sin(u_time/.5)*0.5+1.);
    float resY = u_resolution.y*0.7*(sin(u_time)*0.3+1.);
    float d2 = dist(vec2(resX, resY),gl_FragCoord.xy)*(sin(u_time)+4.5)*0.0009;

    vec3 col = vec3(0.0);

    vec3 ro = vec3(0.0, 0.0, 4.0 );
    vec3 rd = normalize( vec3(p,-2.0) );
    float tmin = 1e10;
    
    // sphere animation
    vec4 sph = vec4( cos( u_time + vec3(1.0,1.0,1.0) + 0.0 )*vec3(0.1,0.1,0.1), 0.2*sin(u_time)+.7 );
    sph.y += .6;

    float t2 = sphIntersect( ro, rd, sph );

    if( t2>0.0 && t2<tmin )
    {
        tmin = t2;
        float t = t2;
        vec3 pos = ro + t*rd;
        vec3 nor = normalize( pos - sph.xyz );
        col = u_ball_color;
        col *= 0.6+0.4*nor.y;
    }

    vec4 first = mix(u_first_color, u_base_color, d2);
    vec4 lifeform = mix(u_accent_color, first, d);

    // if (lifeform.x < e && lifeform.y < e && lifeform.z < e) {

    // }

    vec4 final_col = mix(lifeform, vec4(col, 0.), .9);

    gl_FragColor = max(final_col, lifeform);
}`;

export default frag;
