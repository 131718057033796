export default {
  ETHEREUM_ACCOUNT_CHANGED: "ETHEREUM_ACCOUNT_CHANGED",
  ETHEREUM_ACCOUNT_FAILURE: "ETHEREUM_ACCOUNT_FAILURE",
  ETHEREUM_CHAIN_CHANGED: "ETHEREUM_CHAIN_CHANGED",
  ETHEREUM_LIFEFORM_SUCCESS: "ETHEREUM_LIFEFORM_SUCCESS",
  ETHEREUM_LIFEFORM_INITIALIZE: "ETHEREUM_LIFEFORM_INITIALIZE",
  ETHEREUM_INITIALIZE: "ETHEREUM_INITIALIZE",
  ETHEREUM_TRANSACTIONS_ADD: "ETHEREUM_TRANSACTIONS_ADD",
  ETHEREUM_TRANSACTIONS_UPDATE: "ETHEREUM_TRANSACTIONS_UPDATE",
};
