import PropTypes from "prop-types";
import React from "react";

const View = (props) => {
  return <div>{props.children}</div>;
};

View.propTypes = {
  children: PropTypes.node.isRequired,
};

export default View;
