import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { generateRequestId } from "../middleware/api";
import { getCached } from "../services/cache";

// Create a random, unique request id
export const useRequestId = () => {
  return useMemo(() => {
    return generateRequestId();
  }, []);
};

// Returns the current state of an request via its id
export const useRequest = (requestId, { onError, onSuccess } = {}) => {
  const {
    isError = false,
    isPending = false,
    isSuccess = false,
    error = null,
  } = useSelector((state) => {
    return state.api.requests[requestId] || {};
  });

  const response = useMemo(() => {
    if (isSuccess) {
      return getCached(requestId);
    }

    return {};
  }, [isSuccess, requestId]);

  useEffect(() => {
    if (isError && onError) {
      onError(error);
    } else if (isSuccess && onSuccess) {
      onSuccess(response);
    }
  }, [isError, isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    response,
    isError,
    isPending,
    isSuccess,
  };
};
