import React, { Fragment } from "react";

import InputField from "./InputField";
import web3Validators from "../helpers/web3Validators";

const schema = {
  to: web3Validators.web3().address().required(),
};

const FormGive = () => {
  return (
    <Fragment>
      <InputField label={"To:"} name={"to"} validate={schema.to} />
    </Fragment>
  );
};

export default FormGive;
