import React, { useEffect, useState, Fragment } from "react";
// import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  handleLifeformSuccess,
  addPendingTransaction,
} from "../store/ethereum/actions";
import Loader from "./Loader";
import { birth, TX_BIRTH, getLifeform } from "../services/contracts/lifeforms";
import { usePendingTransaction } from "../hooks/ethereum";
import { postRequest, getRequest } from "../store/api/actions";
import { useRequestId, useRequest } from "../hooks/request";

import "../styles/Mint.css";
import translate from "../services/translate";

const Mint = () => {
  const dispatch = useDispatch();
  const metadataRequestId = useRequestId();
  const [minting, setMinting] = useState(false);
  const [nextToken, setNextToken] = useState(0);
  const [isError, setIsError] = useState(false);
  const tokenRequestId = useRequestId();

  const { account } = useSelector((state) => state.ethereum);

  const removeError = () => {
    setIsError(false);
  };

  useEffect(() => {
    dispatch(
      getRequest({
        id: tokenRequestId,
        endpoint: process.env.REACT_APP_API_URL,
        path: ["count"],
      })
    );
  }, [dispatch, tokenRequestId]);

  useRequest(tokenRequestId, {
    onError: () => {
      setIsError(true);
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        setIsError(true);
      } else {
        setNextToken(response.count + 1);
      }
    },
  });

  const check = async (token) => {
    let notValidToken = await getLifeform(token);
    while (notValidToken.birthtime !== "0") {
      token += 1;
      notValidToken = await getLifeform(token);
    }
    return token;
  };

  const onClickMint = async (user) => {
    const token = await check(nextToken);
    setNextToken(token);
    setMinting(true);
    const { txHash, txMethod } = await birth(user, token);
    dispatch(
      addPendingTransaction({
        txHash,
        txMethod,
        params: {
          account: user,
          tokenId: nextToken,
        },
      })
    );
  };

  const buyTx = usePendingTransaction({
    txMethod: TX_BIRTH,
    params: { account, tokenId: nextToken },
  });

  useEffect(() => {
    if (buyTx.isSuccess) {
      dispatch(
        postRequest({
          id: metadataRequestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: ["mint"],
          body: { tokenId: nextToken },
        })
      );
    } else {
      setMinting(false);
    }
  }, [buyTx.isPending, dispatch, metadataRequestId, nextToken]); // eslint-disable-line

  useRequest(metadataRequestId, {
    onError: () => {
      setMinting(false);
      setIsError(true);
    },
    onSuccess: async (response) => {
      if (response.status === "error") {
        setMinting(false);
        setIsError(true);
      } else {
        setMinting(false);
        dispatch(await handleLifeformSuccess(nextToken));
      }
    },
  });

  return (
    <Fragment>
      {nextToken ? (
        <h2 className="headerMintMsg" onClick={() => onClickMint(account)}>
          {translate("Header.mint")}
        </h2>
      ) : null}
      {minting && <Loader />}
      {isError && (
        <p className="error" onClick={removeError}>
          {translate("Mint.error")}
        </p>
      )}
    </Fragment>
  );
};

export default Mint;
