import { combineReducers } from "redux";

import ethereum from "./ethereum/reducers";
import api from "./api/reducers";

const rootReducer = combineReducers({
  api,
  ethereum,
});

export default rootReducer;
