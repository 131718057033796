import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import View from "../components/View";
import { getLifeform } from "../services/contracts/lifeforms";
import FoundGLSLWrapper from "../components/FoundGLSLWrapper";
import { useRequestId } from "../hooks/request";
import { getRequest } from "../store/api/actions";
import { chooseColour } from "../services/procGen";
import embed_frag from "../fragments/embed-frag";
import { getSeed } from "../services/contracts/lifeforms";

import "../styles/Embed.css";

const Embed = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [lifeformData, setLifeformData] = useState({});
  const [frag, setFrag] = useState("");
  const requestId = useRequestId();
  const [uniforms, setUniforms] = useState({});
  const [seed, setSeed] = useState("");
  const [isLifeformLoading, setIsLifeformLoading] = useState(true);

  useEffect(() => {
    setFrag(embed_frag);
    setIsLoading(false);
  }, [setFrag, id, seed, lifeformData.birthtime]);

  useEffect(() => {
    const firstGenLifeform = (seed) => {
      const colour = chooseColour(seed);
      setUniforms({
        u_base_color: colour,
      });
    };

    const getLifeformData = async () => {
      const lifeform = await getLifeform(id);
      const seeed = await getSeed(id);
      firstGenLifeform(seeed);
      setSeed(seeed);
      setLifeformData(lifeform);
      setIsLifeformLoading(false);
    };

    getLifeformData();
  }, [setLifeformData, id]);

  useEffect(() => {
    dispatch(
      getRequest({
        id: requestId,
        endpoint: process.env.REACT_APP_API_URL,
        path: [id],
      })
    );
    const interval = setInterval(() => {
      dispatch(
        getRequest({
          id: requestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: [id],
        })
      );
      window.location.reload();
    }, 3600000);
    return () => clearInterval(interval);
  }, [dispatch, id, requestId]);

  useEffect(() => {
    const getLifeformData = async () => {
      const lifeform = await getLifeform(id);
      setLifeformData(lifeform);
    };

    getLifeformData();
  }, [setLifeformData, id]);

  return (
    <Fragment>
      <View>
        {!isLoading && !isLifeformLoading && (
          <FoundGLSLWrapper
            class="embedContainer"
            frag={frag}
            setUniforms={uniforms}
          />
        )}
      </View>
    </Fragment>
  );
};

export default Embed;
