import { request, gql } from "graphql-request";

const endpoint = `${process.env.REACT_APP_GRAPH_NODE_ENDPOINT}/${process.env.REACT_APP_SUBGRAPH_NAME}`;

export default async function requestGraph(query, variables) {
  return await request(endpoint, query, variables);
}

export const ownersQuery = (address) => gql`
  {
    owner (id: "${address}") {
      id
      lifeforms {
        id
      }
    }
  }
`;
