import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import "./styles/index.css";
import App from "./App";
import store from "./configureStore";

const Root = (props) => (
  <Provider store={props.store}>
    <App />
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

ReactDOM.render(<Root store={store} />, document.getElementById("root"));
