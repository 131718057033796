import PropTypes from "prop-types";
import React from "react";
import translate from "../services/translate";
import { useFormContext } from "react-form";

const ButtonSubmit = ({ children, isDisabled, ...rest }) => {
  const { meta } = useFormContext();

  return (
    <button
      className="submit"
      disabled={isDisabled || !meta.canSubmit}
      type="submit"
      {...rest}
    >
      {meta.isSubmitting
        ? "..."
        : children || translate("default.buttonSubmit")}
    </button>
  );
};

ButtonSubmit.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
};

export default ButtonSubmit;
