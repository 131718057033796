import React, { Fragment, useState } from "react";

import View from "../components/View";
import Header from "../components/Header";
import Lifeform from "../components/Lifeform";
import Give from "../components/Give";
import { useSelector } from "react-redux";
import FoundGLSLWrapper from "../components/FoundGLSLWrapper";
import frag1 from "../fragments/gallery-1";
import frag2 from "../fragments/gallery-2";
import frag3 from "../fragments/gallery-3";

import translate from "../services/translate";

import "../styles/Home.css";

const uniforms = {};

const Home = () => {
  const { account, ownedLifeforms } = useSelector((state) => state.ethereum);
  const [giving, setGiving] = useState(0);

  const reverseOwned = (owned) => {
    if (!owned || owned.length === 0) return [];
    let reversed = [];
    for (let i = owned.length - 1; i >= 0; i--) {
      reversed.push(owned[i]);
    }
    return reversed;
  };

  const stopGiving = () => {
    setGiving(0);
  };

  return (
    <Fragment>
      <View>
        <Header />
        {account ? (
          <Fragment>
            {!ownedLifeforms || ownedLifeforms.length === 0 ? (
              <p className="no-lifeforms">{translate("Home.noLifeforms")}</p>
            ) : (
              reverseOwned(ownedLifeforms).map((id) => (
                <Lifeform id={id} key={id} setGiving={setGiving} />
              ))
            )}
            {giving ? <Give stopGiving={stopGiving} tokenId={giving} /> : null}
          </Fragment>
        ) : (
          <Fragment>
            <FoundGLSLWrapper
              class="homeContainer1"
              frag={frag1}
              setUniforms={uniforms}
            />
            <p className="homeText1">
              {translate("About.para1")}
              <br />
              <br />
              {translate("About.para1point5")}
              <br />
              <br />
              {translate("About.para1point6")}
            </p>
            <FoundGLSLWrapper
              class="homeContainer2"
              frag={frag2}
              setUniforms={uniforms}
            />
            <p className="homeText2">
              {translate("About.para2")}
              <br />
              <br />
              {translate("About.para2point5")}
            </p>
            <FoundGLSLWrapper
              class="homeContainer3"
              frag={frag3}
              setUniforms={uniforms}
            />
            <p className="homeText3">
              {translate("About.para4")}
              <br />
              <br />
              {translate("About.para4point5")}
            </p>
          </Fragment>
        )}
      </View>
    </Fragment>
  );
};

export default Home;
