export const API_REQUEST = Symbol("api-request");

function apiRequest(
  endpoint,
  path,
  method = "GET",
  id = null,
  body = null,
  types = {}
) {
  return {
    [API_REQUEST]: {
      id,
      endpoint,
      path,
      body,
      method,
      types,
    },
  };
}

export function putRequest({ endpoint, path, body, id }, types) {
  return apiRequest(endpoint, path, "PUT", id, body, types);
}

export function getRequest({ endpoint, path, params, id }, types) {
  return apiRequest(endpoint, path, "GET", id, params, types);
}

export function postRequest({ endpoint, path, body, id }, types) {
  return apiRequest(endpoint, path, "POST", id, body, types);
}

export function destroyRequest({ endpoint, path, body, id }, types) {
  return apiRequest(endpoint, path, "DELETE", id, body, false, types);
}
