import React, { Fragment, useState, useEffect } from "react";

import View from "../components/View";
import FoundGLSLWrapper from "../components/FoundGLSLWrapper";
import ar_frag from "../fragments/ar-frag";
import { colours, scale } from "../services/procGen";

import "../styles/AR.css";

const AR = () => {
  const [frag, setFrag] = useState("");
  const [uniforms, setUniforms] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      const marker = document.querySelector("a-marker");
      marker.addEventListener("markerFound", () => {
        const shader = document.getElementById("canvasContainer");
        shader.classList.remove("hidden");
        shader.style.zIndex = "100";
      });
      marker.addEventListener("markerLost", () => {
        const shader = document.getElementById("canvasContainer");
        shader.style.zIndex = "-10";
        shader.classList.add("hidden");
      });
    });
  });

  useEffect(() => {
    setFrag(ar_frag);
    const ballColours = [
      { colour: [1.2, 0.5, 0.5], name: "red" },
      { colour: [0.5, 0.5, 1.2], name: "blue" },
      { colour: [0.5, 1.2, 0.5], name: "green" },
    ];
    const pickOne = Math.round(scale(Math.random(), 0, 3));
    const pickTwo = Math.round(scale(Math.random(), 0, 9));
    const pickThree = Math.round(scale(Math.random(), 0, 9));
    const u_colours = {
      u_first_color: colours[pickTwo].colour,
      u_accent_color: colours[pickThree].colour,
      u_ball_color: ballColours[pickOne].colour,
    };
    console.log(u_colours) // eslint-disable-line
    setUniforms(u_colours);
    setIsLoading(false);
  }, [setFrag]);

  return (
    <Fragment>
      <View>
        <a-scene
          arjs="sourceType: webcam; debugUIEnabled: false;"
          vr-mode-ui="enabled: false"
        >
          <a-marker type="pattern" url="/patterns/pattern-red.patt"></a-marker>
        </a-scene>
        {!isLoading && (
          <FoundGLSLWrapper
            class="ARcanvas hidden"
            frag={frag}
            setUniforms={uniforms}
          />
        )}
      </View>
    </Fragment>
  );
};

export default AR;
