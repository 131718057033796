import React, { Fragment } from "react";

import View from "../components/View";

import "../styles/Home.css";

const GalleryHome = () => {
  return (
    <Fragment>
      <View>
        <a href="/about">
          <h2 className="about">About lifeforms</h2>
        </a>
        <iframe
          className="iframe-1"
          src="https://lifeforms.supply/gallery/1"
        ></iframe>
        <iframe
          className="iframe-1"
          src="https://lifeforms.supply/gallery/2"
        ></iframe>
        <iframe
          className="iframe-1"
          src="https://lifeforms.supply/gallery/3"
        ></iframe>
      </View>
    </Fragment>
  );
};

export default GalleryHome;
