import React, { useEffect, useRef } from "react";
import GlslCanvas from "glslCanvas";
import PropTypes from "prop-types";

const ShaderCanvas = (props) => {
  const canvasRef = useRef(document.createElement("canvas"));
  const containerRef = useRef(document.createElement("div"));

  const resizer = (
    canvas: HTMLCanvasElement,
    container: HTMLDivElement
  ): void => {
    canvas.width = container.clientWidth + window.devicePixelRatio;
    canvas.height = container.clientHeight + window.devicePixelRatio;
    canvas.style.width = container.clientWidth + "px";
    canvas.style.height = container.clientHeight + "px";
  };

  useEffect(() => {
    const node = canvasRef.current;
    const container = containerRef.current;
    const sandbox = new GlslCanvas(canvasRef.current);
    sandbox.load(props.frag);
    for (let k in props.setUniforms) {
      sandbox.setUniform(k, ...props.setUniforms[k]);
    }
    resizer(node, container);

    const handler = () => {
      if (
        node.clientWidth !== container.clientWidth ||
        node.clientHeight !== container.clientHeight
      )
        resizer(canvasRef.current, containerRef.current);
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [props.frag, props.setUniforms]);

  return (
    <div className={props.class} id="canvasContainer" ref={containerRef}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

ShaderCanvas.propTypes = {
  class: PropTypes.string.isRequired,
  frag: PropTypes.string,
  setUniforms: PropTypes.object,
};

export default ShaderCanvas;
