import PropTypes from "prop-types";
import React from "react";

import InputFieldset from "./InputFieldset";
import { useField } from "../hooks/form";

// eslint-disable-next-line react/display-name
const InputField = React.forwardRef(
  ({ name, validate, label, isDisabled, value }, ref) => {
    const { meta, getInputProps } = useField(name, { validate });
    return (
      <InputFieldset label={label} meta={meta} name={name}>
        <input
          {...getInputProps({
            ref,
            id: name,
            label,
            value,
            validate,
          })}
          disabled={isDisabled}
          ref={ref}
        />
      </InputFieldset>
    );
  }
);

InputField.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.object.isRequired,
  value: PropTypes.string,
};

export default InputField;
