import PropTypes from "prop-types";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addPendingTransaction,
  getInitialLifeforms,
} from "../store/ethereum/actions";
import FormGive from "./FormGive";
import ButtonSubmit from "./ButtonSubmit";
import Modal from "./Modal";
import Loader from "./Loader";
import { give, TX_GIVE } from "../services/contracts/lifeforms";
import { usePendingTransaction } from "../hooks/ethereum";
import { useForm } from "../hooks/form";

const Give = ({ tokenId, stopGiving }) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.ethereum);
  const [loading, setLoading] = useState(false);

  const { Form } = useForm({
    onSubmit: ({ to }) => {
      setLoading(true);
      onClickGive(account, to, tokenId);
    },
  });

  const onClickGive = async (from, to, tokenId) => {
    const { txHash, txMethod } = await give(from, to, tokenId);
    dispatch(
      addPendingTransaction({
        txHash,
        txMethod,
        params: {
          from,
          tokenId,
        },
      })
    );
  };

  const giveTx = usePendingTransaction({
    txMethod: TX_GIVE,
    params: { from: account, tokenId },
  });

  useEffect(() => {
    if (giveTx.isSuccess) {
      setLoading(false);
      stopGiving();
      const refresh = async () => {
        dispatch(await getInitialLifeforms(account));
      };
      refresh();
    }
  }, [giveTx.isPending, dispatch]); // eslint-disable-line

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Modal close={stopGiving}>
          <Form>
            <FormGive />
            <ButtonSubmit>SEND</ButtonSubmit>
          </Form>
        </Modal>
      )}
    </Fragment>
  );
};

Give.propTypes = {
  stopGiving: PropTypes.func.isRequired,
  tokenId: PropTypes.number.isRequired,
};

export default Give;
