import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./views/Home";
import About from "./views/About";
import Gallery from "./views/Gallery";
import AR from "./views/AR";
import GalleryHome from "./views/GalleryHome";
import TokenView from "./views/TokenView";
import Embed from "./views/Embed";

const SessionContainer = ({ component: Component }) => {
  return <Component />;
};

const PublicRoute = ({ component, path }) => {
  return (
    <Route path={path}>
      <SessionContainer component={component} />
    </Route>
  );
};

// prettier-ignore
const Routes = () => (
  <Switch>
    <PublicRoute
      component={Home}
      exact
      path="/"
    />
    <PublicRoute
      component={About}
      exact
      path="/about"
    />
    <PublicRoute
      component={GalleryHome}
      exact
      path="/gallery"
    />
    <PublicRoute
      component={AR}
      exact
      path="/ar"
    />
    <PublicRoute
      component={TokenView}
      exact
      path="/:id"
    />
    <PublicRoute
      component={Gallery}
      exact
      path="/gallery/:id"
    />
    <PublicRoute
      component={Embed}
      exact
      path="/embed/:id"
    />
  </Switch>
);

SessionContainer.propTypes = {
  component: PropTypes.elementType.isRequired,
  isTokenRequired: PropTypes.bool,
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};

export default Routes;
