import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

import Whiteout from "./Whiteout";
import "../styles/Modal.css";

const Modal = (props) => {
  const [display, setDisplay] = useState(true);

  const onClick = () => {
    setDisplay(false);
    if (props.close) {
      props.close();
    }
  };

  return display ? (
    <Fragment>
      <Whiteout onClick={onClick} />
      <div className="modal">
        <img
          className="close"
          src="https://upload.wikimedia.org/wikipedia/commons/c/c1/High-contrast-dialog-close.svg"
          onClick={onClick}
        />
        <div className="modal-contents">{props.children}</div>
      </div>
    </Fragment>
  ) : null;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func,
};

export default Modal;
