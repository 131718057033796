import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { getLifeform, getSeed } from "../services/contracts/lifeforms";
import FoundGLSLWrapper from "../components/FoundGLSLWrapper";
import { useRequestId, useRequest } from "../hooks/request";
import { getRequest, postRequest } from "../store/api/actions";
import InfoPanel from "../components/InfoPanel";
import base_frag from "../fragments/basic-frag";
import two_frag from "../fragments/two-frag";
import translate from "../services/translate";
import { chooseColour, chooseSecondColour } from "../services/procGen";
import { isOlderThan } from "../utils/age";

import "../styles/Lifeform.css";

// const uniforms = {
//   u_base_color: [0.98039, 0.313725, 0.478431, 1.0],
// };

const Lifeform = ({ id, setGiving }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLifeformLoading, setIsLifeformLoading] = useState(true);
  const [lifeformData, setLifeformData] = useState({});
  const [frag, setFrag] = useState("");
  const requestId = useRequestId();
  const fixMetadataRequestId = useRequestId();
  const [description, setDescription] = useState("");
  const [seed, setSeed] = useState("");
  const [uniforms, setUniforms] = useState({});

  useEffect(() => {
    if (seed !== "" && isOlderThan(lifeformData.birthtime, 7776000)) {
      setFrag(two_frag);
      setIsLoading(false);
    } else if (seed !== "") {
      setFrag(base_frag);
      setIsLoading(false);
    }
  }, [setFrag, id, seed, lifeformData.birthtime]);

  useRequest(requestId, {
    onError: () => {
      setDescription("");
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        setDescription("");
        dispatch(
          postRequest({
            id: fixMetadataRequestId,
            endpoint: process.env.REACT_APP_API_URL,
            path: ["mint"],
            body: { tokenId: id },
          })
        );
      } else {
        setDescription(response.description);
      }
    },
  });

  useEffect(() => {
    dispatch(
      getRequest({
        id: requestId,
        endpoint: process.env.REACT_APP_API_URL,
        path: [id],
      })
    );
    const interval = setInterval(() => {
      dispatch(
        getRequest({
          id: requestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: [id],
        })
      );
      window.location.reload();
    }, 3600000);
    return () => clearInterval(interval);
  }, [dispatch, id, requestId]);

  useEffect(() => {
    const firstGenLifeform = (seed) => {
      const colour = chooseColour(seed);
      setUniforms({
        u_base_color: colour,
      });
    };

    const secondGenLiferorm = (seed) => {
      const first = chooseColour(seed);
      const second = chooseSecondColour(seed);
      setUniforms({
        u_base_color: first,
        u_second_color: second,
      });
    };

    const getLifeformData = async () => {
      const lifeform = await getLifeform(id);
      const seeed = await getSeed(id);
      if (isOlderThan(lifeform.birthtime, 7776000)) {
        secondGenLiferorm(seeed);
      } else {
        firstGenLifeform(seeed);
      }
      setSeed(seeed);
      setLifeformData(lifeform);
      setIsLifeformLoading(false);
    };

    getLifeformData();
  }, [setLifeformData, id]);

  return (
    <div className="lifeformContainer">
      {!isLifeformLoading && lifeformData.tokenURI === "" ? (
        <p className="no-lifeforms">{translate("Lifeform.noLifeforms")}</p>
      ) : (
        <Fragment>
          {!isLoading && !isLifeformLoading && (
            <FoundGLSLWrapper
              class="canvasContainer"
              frag={frag}
              setUniforms={uniforms}
            />
          )}
          {!isLifeformLoading && (
            <InfoPanel
              birthtime={parseInt(lifeformData.birthtime)}
              currentOwner={lifeformData.owner}
              description={description}
              lastTransfer={parseInt(lifeformData.ownerBeginning)}
              seed={seed}
              setGiving={setGiving}
              tokenId={id.toString()}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

Lifeform.propTypes = {
  id: PropTypes.number.isRequired,
  setGiving: PropTypes.func,
};

export default Lifeform;
