import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import View from "../components/View";
import Lifeform from "../components/Lifeform";

const TokenView = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <View>
        <Lifeform id={parseInt(id)} />
      </View>
    </Fragment>
  );
};

export default TokenView;
